import request from '@/utils/request';
import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../axiosBaseQuery';
import BaseToast from '@/components/base/BaseToast/BaseToast';

export const groupApi = createApi({
  reducerPath: 'groupApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    GetGroupDetail: builder.query<any, number>({
      query: (groupId) => ({
        url: `/api/group/GetGroupDetail`,
        method: 'GET',
        params: { groupId: groupId },
      }),
      transformResponse: (response) => response.data.data,
    }),
    UpdateGroupGeofenceRadius: builder.mutation<any, number>({
      query: (data) => ({
        url: `/api/group/UpdateGroupGeofenceRadius`,
        method: 'PUT',
        data: data,
      }),
      transformResponse: (response) => response.data.data,
    }),
  }),
});

export const { 
  useGetGroupDetailQuery, 
  useUpdateGroupGeofenceRadiusMutation
 } = groupApi;
