import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  recordFlexPane: false,
};

const timesheetSlice = createSlice({
  name: 'timesheet',
  initialState,
  reducers: {
    openRecordFlexPane: (state) => {
      state.recordFlexPane = true;
    },
    closeRecordFlexPane: (state) => {
      state.recordFlexPane = false;
    },
  },
});

export const { openRecordFlexPane, closeRecordFlexPane } =
  timesheetSlice.actions;
export default timesheetSlice.reducer;
