import { createSlice } from '@reduxjs/toolkit';
import { groupApi } from './groupAPI';

interface InitialState {
  detail: any;
}

const initialState: InitialState = {
  detail: {},
};

const groupSlice = createSlice({
  name: 'group',
  initialState,
  reducers: {
    setGeofence: (state, action) => {
      state.detail.geofenceRadius = action.payload.geofenceRadius;
      state.detail.isGeofenceRadiusCheck = action.payload.isGeofenceRadiusCheck;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      groupApi.endpoints.GetGroupDetail.matchFulfilled,
      (state, action) => {
        state.detail = action.payload;
      },
    );
  },
});

export const { setGeofence } = groupSlice.actions;

export default groupSlice.reducer;
