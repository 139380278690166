import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../axiosBaseQuery';
import { AxiosResponse } from 'axios';
import {
  ReportTemplateUpsertRequest,
  ReportTemplateWithData,
  AllSpaceReportData,
} from './template.interface';

export const templateApi = createApi({
  reducerPath: 'templateApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    createTemplate: builder.mutation<any, ReportTemplateUpsertRequest>({
      query: (data) => ({
        url: '/api/Report/CreateTemplate',
        method: 'POST',
        data,
      }),
      transformResponse: (response: AxiosResponse<any>) => {
        return response.data.data;
      },
    }),
    queryTemplateByFilter: builder.query<ReportTemplateWithData, any>({
      query: (data) => ({
        url: '/api/Report/QueryTemplateByFilter',
        method: 'POST',
        data,
      }),
      transformResponse: (response: AxiosResponse<any>) => {
        return response.data.data;
      },
    }),
    getAllTemplates: builder.query<AllSpaceReportData, any>({
      query: () => ({
        url: '/api/Report/GetAllTemplates',
        method: 'GET',
      }),
      transformResponse: (response: AxiosResponse<any>) => {
        return response.data.data;
      },
    }),
  }),
});

export const {
  useCreateTemplateMutation,
  useQueryTemplateByFilterQuery,
  useGetAllTemplatesQuery,
} = templateApi;
